import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Tooltip,
  Typography,
  Avatar,
  Link as MuiLink,
  IconButton,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import Wallet from '@mui/icons-material/Wallet';
import { getSerializedSearch } from '../../helpers';
import RelatedDocumentsMenu from '../menus/RelatedDocumentsMenu';
import CompanyBlacklistComponent from './CompanyBlacklistComponent';
import CompanyDebtsSummary from './CompanyDebtsSummary';
import selectedCompanyFilter from '../filters/selectedCompanyFilter';
import CompanyProfileDialog from '../dialogs/CompanyProfileDialog';
import DebtorProfileDialog from '../dialogs/DebtorProfileDialog';
import { useBooleanState, useGetUser } from '../../hooks';
import { userHasRoutePermission } from '../../helpers/routes';
import DownloadCessionsSummaryDialog from '../dialogs/DownloadCessionsSummaryDialog';
import getCdnUrl from '../../helpers/get-cdn-url';
import { REQUESTING_PLATFORM_TO_CODES } from '../../constants/requesting-platform';
import DebtorRatificationInformationTooltip from '../tooltips/DebtorRatificationInformationTooltip';

const SANTANDER_IMAGE_URL = getCdnUrl(
  '/common_assets/assets/icons/santander_image.png',
);

const CompanyProfileCell = ({
  masterEntity,
  showRelatedDocuments,
  showDicom,
  type,
  debtsShowType,
}) => {
  const [open, setOpen] = useBooleanState(false);
  const history = useHistory();
  const user = useGetUser();
  const [pressed, togglePressed] = useBooleanState();
  const showProfile = useMemo(
    () => userHasRoutePermission(user, 'customers.view_masterentity'),
    [],
  );

  const handleClick = () => {
    if (showRelatedDocuments) {
      selectedCompanyFilter(masterEntity);
      history.push(
        `/app/comercial/invoices-management/preoffers-manager?${getSerializedSearch(
          { companyId: masterEntity.id },
        )}`,
      );
    } else {
      setOpen(true);
    }
  };

  if (!masterEntity) return null;

  const conservativeTycRequestingPlatformCode = useMemo(
    () => masterEntity?.company?.conservativeTycRequestingPlatform?.code,
    [masterEntity],
  );

  return (
    <>
      <Tooltip title="Descargar resumenes cliente">
        <IconButton onClick={togglePressed}>
          <Wallet />
        </IconButton>
      </Tooltip>
      <Stack spacing={0} direction="column">
        <Stack spacing={0.5} direction="row">
          <MuiLink
            textAlign="initial"
            variant="body2"
            rel="noopener noreferrer"
            underline="hover"
            onClick={handleClick}
          >
            <Typography style={{ wordWrap: 'break-word' }}>
              {masterEntity.name || masterEntity.displayNationalIdentifier}
            </Typography>
          </MuiLink>
          {showRelatedDocuments && (
            <RelatedDocumentsMenu masterEntity={masterEntity} />
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{masterEntity.displayNationalIdentifier}</Typography>
          <CompanyBlacklistComponent
            masterEntity={masterEntity}
            disableDisplay={!showDicom}
          />
          <CompanyDebtsSummary
            masterEntity={masterEntity}
            type={debtsShowType || type}
          />
          {conservativeTycRequestingPlatformCode
            === REQUESTING_PLATFORM_TO_CODES.SANTANDER && (
            <Tooltip
              title={`Empresa proveniente de ${conservativeTycRequestingPlatformCode}`}
            >
              <Avatar
                src={SANTANDER_IMAGE_URL}
                sx={{ width: 15, height: 15 }}
              />
            </Tooltip>
          )}
          {type === 'receiver' && (
          <DebtorRatificationInformationTooltip
            debtor={masterEntity.debtor}
          />
          )}
        </Stack>
      </Stack>
      {open
        && !showRelatedDocuments
        && (type === 'receiver' && !showProfile ? (
          <DebtorProfileDialog
            masterEntityId={masterEntity.id}
            open={open}
            setOpen={setOpen}
            showDicom={showDicom}
          />
        ) : (
          <CompanyProfileDialog
            masterEntityId={masterEntity.id}
            open={open}
            setOpen={setOpen}
            showDicom={showDicom}
          />
        ))}
      {pressed && (
        <DownloadCessionsSummaryDialog
          customCompany={masterEntity.id}
          open={pressed}
          onClose={togglePressed}
          product="FACTORING"
        />
      )}
    </>
  );
};

CompanyProfileCell.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    source: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    debtor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      ratificationInformation: PropTypes.shape({
        id: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
      }),
    }).isRequired,
    name: PropTypes.string.isRequired,
    displayNationalIdentifier: PropTypes.string.isRequired,
    company: PropTypes.shape({
      pendingReintegrations: PropTypes.shape({
        count: PropTypes.number,
      }),
      conservativeTycRequestingPlatform: PropTypes.shape({
        code: PropTypes.string,
      }),
      debtInvoices: PropTypes.shape({
        count: PropTypes.number,
        amountWithIva: PropTypes.shape({
          chileFormat: PropTypes.string,
        }),
      }),
    }),
    riskBlacklist: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  showRelatedDocuments: PropTypes.bool,
  showDicom: PropTypes.bool,
  type: PropTypes.oneOf(['company', 'receiver']),
  debtsShowType: PropTypes.string,
};

CompanyProfileCell.defaultProps = {
  showRelatedDocuments: false,
  showDicom: true,
  type: 'company',
  debtsShowType: null,
};

export default CompanyProfileCell;
