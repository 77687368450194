import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomerDocumentsCell from './CustomerDocumentsCell';

const CustomerDocumentsAccordion = ({ masterEntity, showGenerateContract, requiredYears }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const requiredYearsArray = Array.from(
    { length: requiredYears },
    (_, index) => currentYear - index - 1,
  );

  const [expandedYear, setExpandedYear] = useState(null);

  const handleYearAccordionChange = (year) => (event, isExpanded) => {
    setExpandedYear(isExpanded ? year : null);
  };

  return (
    <Grid container direction="column" bgcolor="gray.A100" sx={{ mr: 2.5 }}>
      <Accordion
        expandIconPosition="start"
        bgcolor="gray.A100"
        elevation="0.2"
        sx={{ backgroundColor: 'gray.A100', mr: 2 }}
      >
        <AccordionSummary
          // expand icon to the left
          expandIconPosition="start"
          bgcolor="gray.A100"
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <CustomerDocumentsCell
            masterEntity={masterEntity}
            showGenerateContract={showGenerateContract}
            wrap
          />
        </AccordionSummary>
        {requiredYearsArray.map((year) => (
          <AccordionDetails
            key={year}
            expanded={expandedYear === year}
            onChange={handleYearAccordionChange(year)}
          >
            <Typography>{year}</Typography>
            <CustomerDocumentsCell
              masterEntity={masterEntity}
              showGenerateContract={showGenerateContract}
              year={String(year)}
            />
          </AccordionDetails>
        ))}
      </Accordion>
    </Grid>
  );
};

CustomerDocumentsAccordion.propTypes = {
  showGenerateContract: PropTypes.bool,
  masterEntity: PropTypes.shape({
    country: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      documentTypes: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        shortName: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          globalAppId: PropTypes.string.isRequired,
          lastFile: PropTypes.string,
          documentType: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  requiredYears: PropTypes.number,
};

CustomerDocumentsAccordion.defaultProps = {
  showGenerateContract: false,
  requiredYears: 2,
};

export default CustomerDocumentsAccordion;
