import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { COMPANY_LEGAL_REPRESENTATIVES } from '../../graphql';
import { FingoDialog } from '../dialogs';
import { FingoDataGrid } from '../dataGrids';
import { LegalRepresentativeColumns } from '../../constants';
import { formatDateTime } from '../../helpers';
import dayjs from '../../config/dayjs';

const CompanyBlacklistComponent = ({ masterEntity, disableDisplay }) => {
  const { riskBlacklist } = masterEntity;
  if (!riskBlacklist) return null;
  const { blacklistType, active } = riskBlacklist;
  if (!active) return null;
  const [open, setOpen] = useState(false);
  const [loadLR, { data }] = useLazyQuery(COMPANY_LEGAL_REPRESENTATIVES, {
    variables: { masterEntityId: masterEntity.id },
    onCompleted: () => {
      setOpen(true);
    },
  });
  const legalRepresentative = data?.getMasterEntity?.company?.legalRepresentative || [];
  const backgroundColor = blacklistType === 'BLACKLIST' ? '#d32f2f' : '#ff9800';
  return (
    <>
      <Tooltip
        title={`Empresa en la ${
          blacklistType === 'BLACKLIST' ? 'Blacklist' : 'Watchlist'
        }`}
      >
        <span>
          <IconButton
            disabled={disableDisplay}
            onClick={loadLR}
            sx={{
              width: 17,
              height: 17,
              fontSize: 11,
              ml: 0.1,
              backgroundColor,
              '&:disabled': { backgroundColor },
              '&:hover': { backgroundColor },
            }}
          >
            <Typography sx={{ color: '#ffffff' }}>
              {blacklistType[0]}
            </Typography>
          </IconButton>
        </span>
      </Tooltip>
      <FingoDialog
        open={open}
        title={`Blacklist ${masterEntity.name}`}
        subtitle={`Creación: ${formatDateTime(
          dayjs(masterEntity.riskBlacklist?.createdAt),
        )}`}
        handleClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <Stack spacing={1} direction="column">
          <Typography variant="h4">Comentarios</Typography>
          <TextField
            variant="outlined"
            fullWidth
            minRows={3}
            multiline
            value={
              masterEntity.riskBlacklist?.comments
              || 'Sin comentarios asociados'
            }
            disabled
          />
          <Typography variant="h4">Composición Sociedad</Typography>
          <FingoDataGrid
            rows={legalRepresentative}
            columns={LegalRepresentativeColumns.filter(
              (col) => !['dicomDetails'].includes(col.field),
            )}
            hideFooter
          />
        </Stack>
      </FingoDialog>
    </>
  );
};

CompanyBlacklistComponent.propTypes = {
  disableDisplay: PropTypes.bool,
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    riskBlacklist: PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string,
      comments: PropTypes.string,
      blacklistType: PropTypes.string,
      active: PropTypes.bool,
    }),
  }).isRequired,
};

CompanyBlacklistComponent.defaultProps = {
  disableDisplay: false,
};
export default CompanyBlacklistComponent;
