import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Avatar, CircularProgress } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { MasterEntityType } from '../../propTypes';
import { CONTACT_COLUMNS } from '../../constants/contact-columns';
import ContactsDialog from '../dialogs/ContactsDialog';
import useIsMobile from '../../hooks/useIsMobile';

const ContactInformation = ({ name, position, email, phoneNumber }) => (
  <>
    <Typography>
      {name} ({position})
    </Typography>
    <Typography>{email}</Typography>
    <Typography>{phoneNumber}</Typography>
  </>
);

ContactInformation.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};

const ContactCard = ({ masterEntity, contactType, columns }) => {
  const [openContactsDialog, setOpenContactsDialog] = useState(false);
  const isDesktop = useIsMobile('lg');

  const contacts = masterEntity?.contacts?.edges?.map((edge) => edge.node);
  const contact = contacts?.[0];

  const loading = !masterEntity;

  const contactInfo = useMemo(() => {
    if (loading) return <CircularProgress />;
    if (contact) return <ContactInformation {...contact} />;
    return <Typography variant="h5">+ Nuevo contacto</Typography>;
  }, [loading, contact]);

  return (
    <>
      <Card
        onClick={() => setOpenContactsDialog(true)}
        sx={{ cursor: 'pointer' }}
      >
        <CardContent component={Stack} spacing={1} sx={{ p: 1 }}>
          {!loading && (
            <Typography fontWeight={700} alignSelf="center">
              {contact ? 'Información de contacto' : 'Sin contacto'}
            </Typography>
          )}
          <Stack direction="row" spacing={2} alignItems="center">
            {!isDesktop && !loading && (
              <Avatar>
                <AssignmentIndIcon />
              </Avatar>
            )}
            <Stack
              flexGrow={1}
              spacing={{ desktop: 0.5, lg: 1 }}
              alignItems={{ desktop: 'center', lg: 'flex-start' }}
            >
              {contactInfo}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      {openContactsDialog && (
        <ContactsDialog
          open={openContactsDialog}
          onClose={() => {
            setOpenContactsDialog(false);
          }}
          columns={columns}
          masterEntity={masterEntity}
          contactType={contactType}
        />
      )}
    </>
  );
};

ContactCard.propTypes = {
  masterEntity: MasterEntityType,
  contactType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
};

ContactCard.defaultProps = {
  masterEntity: null,
  columns: CONTACT_COLUMNS,
  contactType: 'COMMERCIAL',
};

export default ContactCard;
