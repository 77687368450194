import React from 'react';
import PropTypes from 'prop-types';
import useGetCountryFromUrl from '../../hooks/useGetCountryFromUrl';
import { MoneyType } from '../../propTypes';
import AmountWithIvaCell from './AmountWithIvaCell';
import AmountToCollectWithDebt from './AmountToCollectWithDebt';
import AmountWithCreditNoteAndDebt from './AmountWithCreditNoteAndDebt';

const AmountToCollectCell = ({ row }) => {
  const country = useGetCountryFromUrl();
  const countryToComponent = {
    Chile: AmountWithCreditNoteAndDebt,
    Mexico: AmountToCollectWithDebt,
  };

  const CountryComponent = countryToComponent[country?.name] || AmountWithIvaCell;
  return (
    <CountryComponent row={row} />
  );
};

AmountToCollectCell.propTypes = {
  row: PropTypes.shape({
    amountWithIva: MoneyType.isRequired,
    invoiceDebt: PropTypes.shape({
      debt: MoneyType,
    }),
    retentionRate: PropTypes.number,
    creditNotes: PropTypes.arrayOf(PropTypes.shape({
      amountWithIva: MoneyType.isRequired,
      id: PropTypes.string.isRequired,
    })),
    debitNotes: PropTypes.arrayOf(PropTypes.shape({
      amountWithIva: MoneyType.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }),

};

AmountToCollectCell.defaultProps = {
  row: {
    amountWithIva: {
      amount: 0,
      currency: 'CLP',
    },
    invoiceDebt: {
      debt: 0,
    },
    offer: {
      retentionRate: 0,
    },
    creditNotes: [],
    debitNotes: [],
  },
};

export default AmountToCollectCell;
