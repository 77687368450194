import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import dayjs from '../../config/dayjs';
import { actionTypeToSpanish } from '../../helpers';

const ActionCell = ({ action }) => (
  <>
    {action ? (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        overflow="hidden"
      >
        <Tooltip title={action.comment} placement="left" arrow>
          <Stack>
            <Typography>{actionTypeToSpanish[action.actionType]}</Typography>
            <Typography variant="body2" noWrap>
              {action.comment}
            </Typography>
            <Typography variant="caption">
              {dayjs(action.createdAt).format('DD-MM-YYYY HH:mm[hrs]')}
            </Typography>
          </Stack>
        </Tooltip>
      </Grid>
    ) : (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        <Typography>Sin gestiones</Typography>
      </Grid>
    )}
  </>
);

ActionCell.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }),
};

ActionCell.defaultProps = {
  action: null,
};

export default ActionCell;
