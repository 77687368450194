import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import { ActionsDrawer } from '../drawer';
import LastActionDate from './LastActionDate';
import { useBooleanState } from '../../hooks';

const ActionDrawerCell = ({
  actions,
  drawerId,
  title,
  subtitle,
  headerTooltipText,
  lastActionDate,
  headerComponent,
  contentComponent,
  iconText,
  disabled,
  icon: Icon,
  ...props
}) => {
  const [drawerOpen, toggleDrawer] = useBooleanState(false);
  const drawerButtonId = drawerId ? `action-drawer-${drawerId}` : 'action-drawer';
  return (
    <Tooltip title={disabled ? 'No hay gestiones sobre este documento' : ''}>
      <Grid container justifyContent="center" alignItems="center" direction="column">
        <IconButton
          id={drawerButtonId}
          onClick={toggleDrawer}
          sx={{ width: 30, height: 30 }}
          disabled={disabled}
        >
          <Badge
            color="primary"
            badgeContent={actions?.length}
            sx={{
              '& .MuiBadge-badge': {
                minWidth: 'auto',
                width: 13,
                height: 13,
                fontSize: 11,
              },
            }}
          >
            {Icon ? <Icon /> : (
              <Avatar
                sx={{
                  width: 28,
                  height: 28,
                  bgcolor: (theme) => theme.palette.gray.main }}
              >
                <FormatListBulleted sx={{ width: 20, height: 20 }} />
              </Avatar>
            )}
          </Badge>
          {iconText && <Typography variant="h2" sx={{ ml: '5px', display: { xs: 'none', sm: 'inline', md: 'inline', lg: 'inline' } }}>Gestiones</Typography>}
        </IconButton>
        <ActionsDrawer
          actions={actions}
          open={drawerOpen}
          title={title}
          subtitle={subtitle}
          headerTooltipText={headerTooltipText}
          setOpen={toggleDrawer}
          headerComponent={headerComponent}
          contentComponent={contentComponent}
          {...props}
        />
        {lastActionDate && (
        <LastActionDate actions={actions} />
        )}
      </Grid>
    </Tooltip>
  );
};

ActionDrawerCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array.isRequired,
  headerComponent: PropTypes.func,
  contentComponent: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  headerTooltipText: PropTypes.string,
  lastActionDate: PropTypes.bool,
  drawerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  iconText: PropTypes.string,
};

ActionDrawerCell.defaultProps = {
  headerComponent: null,
  contentComponent: null,
  subtitle: '',
  headerTooltipText: null,
  lastActionDate: false,
  drawerId: null,
  disabled: false,
  icon: null,
  iconText: null,
};

export default ActionDrawerCell;
